
import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import "./style/tailwindcss.css";
// import * as API from '@/api';
import { Table,TableColumn,Message,InputNumber,DatePicker } from 'element-ui';
// import lang from 'element-ui/lib/locale/lang/en'
// import locale from 'element-ui/lib/locale'
import '@/validate/validate'
import mapBoxGl from 'mapbox-gl'
import * as turf from '@turf/turf'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
 
NProgress.configure({
  minimum: 0.1,
  template: `
    <div class="bar" role="bar">
      <div class="peg"></div>
    </div>
    <div class="spinner" role="spinner">
      <div class="spinner-icon"></div>
    </div>
  `,
  ease: 'ease',
  speed: 200,
})

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.prototype.$turf = turf
Vue.prototype.$mapboxgl = mapBoxGl
Vue.config.productionTip = false;
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(InputNumber)
Vue.use(DatePicker)
Vue.prototype.$message = Message;
// locale.use(lang)
new Vue({
  router,
  store,
  beforeCreate() {
    // Vue.prototype.$API = API;
  },
  render: (h) => h(App),
}).$mount("#app");
